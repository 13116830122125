export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED';

export const GET_PROJECTS_PRICE = 'GET_PROJECTS_PRICE';
export const GET_PROJECTS_PRICE_SUCCESS = 'GET_PROJECTS_PRICE_SUCCESS';
export const GET_PROJECTS_PRICE_FAILED = 'GET_PROJECTS_PRICE_FAILED';

export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAILED = 'GET_PROJECT_FAILED';

export const GET_PROJECT_PRICE = 'GET_PROJECT_PRICE';
export const GET_PROJECT_PRICE_SUCCESS = 'GET_PROJECT_PRICE_SUCCESS';
export const GET_PROJECT_PRICE_FAILED = 'GET_PROJECT_PRICE_FAILED';

export const TOGGLE_PROJECT_TO_WATCHLIST = 'TOGGLE_PROJECT_TO_WATCHLIST';
export const TOGGLE_PROJECT_TO_WATCHLIST_SUCCESS = 'TOGGLE_PROJECT_TO_WATCHLIST_SUCCESS';
export const TOGGLE_PROJECT_TO_WATCHLIST_FAILED = 'TOGGLE_PROJECT_TO_WATCHLIST_FAILED';

export const CLEAR_PROJECT = 'CLEAR_PROJECT';
