import img1 from '../images/coin/btc.png';
import img2 from '../images/coin/eth.png';
import img3 from '../images/coin/bnb.png';
import img4 from '../images/coin/tet.png';
import img5 from '../images/coin/sol.png';
import img6 from '../images/coin/ada.png';
import img7 from '../images/coin/avax.png';

import chart1 from '../images/icon/chart-up.png';
import chart2 from '../images/icon/chart-down.png';

const dataCoin2 = [
  {
    id: 1,
    icon: img1,
    name: 'Bitcoin',
    unit: 'BTC',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart2,
  },
  {
    id: 2,
    icon: img2,
    name: 'Ethereum',
    unit: 'ETH',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart1,

  },
  {
    id: 3,
    icon: img3,
    name: 'Binance',
    unit: 'BNB',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart1,

  },
  {
    id: 4,
    icon: img4,
    name: 'Tether',
    unit: 'USDT',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart2,

  },
  {
    id: 5,
    icon: img5,
    name: 'Solana',
    unit: 'SOL',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart2,

  },
  {
    id: 6,
    icon: img1,
    name: 'XRP',
    unit: 'XRP',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart1,

  },
  {
    id: 7,
    icon: img6,
    name: 'XRP',
    unit: 'XRP',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart1,

  },
  {
    id: 8,
    icon: img7,
    name: 'Cardano',
    unit: 'ADA',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart2,

  },
  {
    id: 9,
    icon: img1,
    name: 'Bitcoin',
    unit: 'BTC',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart1,
  },
  {
    id: 10,
    icon: img2,
    name: 'Ethereum',
    unit: 'ETH',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart2,

  },
  {
    id: 11,
    icon: img3,
    name: 'Binance',
    unit: 'BNB',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart1,

  },
  {
    id: 12,
    icon: img4,
    name: 'Tether',
    unit: 'USDT',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart1,

  },
  {
    id: 13,
    icon: img5,
    name: 'Solana',
    unit: 'SOL',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart1,

  },
  {
    id: 14,
    icon: img1,
    name: 'XRP',
    unit: 'XRP',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart2,

  },
  {
    id: 15,
    icon: img6,
    name: 'XRP',
    unit: 'XRP',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart1,

  },
  {
    id: 16,
    icon: img7,
    name: 'Cardano',
    unit: 'ADA',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart1,
  },
  {
    id: 17,
    icon: img1,
    name: 'Bitcoin',
    unit: 'BTC',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart2,
  },
  {
    id: 18,
    icon: img2,
    name: 'Ethereum',
    unit: 'ETH',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart2,

  },
  {
    id: 19,
    icon: img3,
    name: 'Binance',
    unit: 'BNB',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart1,

  },
  {
    id: 20,
    icon: img4,
    name: 'Tether',
    unit: 'USDT',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart1,

  },
  {
    id: 21,
    icon: img5,
    name: 'Solana',
    unit: 'SOL',
    trader: '2.236',
    change: '-3.33%',
    hight: '62,749.00',
    low: '57,600.00',
    tur: '5.04B(USD)',
    chart: chart2,

  },
];

export default dataCoin2;
