import React from 'react';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap-accordion';

import Sale01 from 'components/sale/Sale01';
import PageTitle from 'components/pagetitle';

import dataFaq from 'assets/fake-data/data-faq';

function Faq() {
  return (
    <div>
      <PageTitle heading="FAQ" title="FAQ" />

      <section className="faq">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block-text center">
                <h3 className="heading">Frequently Asked Questions</h3>
                <p className="desc fs-20">Learn how to get started</p>
              </div>
            </div>

            <div className="col-md-12">
              <div className="flat-accordion">
                {
                  dataFaq.map((idx) => (
                    <Accordion key={idx.id} title={idx.title} show={idx.show}>
                      <p className="toggle-content">{idx.text}</p>

                      <Link to="##">Learn more</Link>
                    </Accordion>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </section>

      <Sale01 />
    </div>
  );
}

export default Faq;
