export const ON_LOGIN_BY_GOOGLE = 'ON_LOGIN_BY_GOOGLE';
export const ON_LOGIN_BY_GOOGLE_SUCCESS = 'ON_LOGIN_BY_GOOGLE_SUCCESS';
export const ON_LOGIN_BY_GOOGLE_FAILED = 'ON_LOGIN_BY_GOOGLE_FAILED';

export const ON_LOGIN_BY_TELEGRAM = 'ON_LOGIN_BY_TELEGRAM';
export const ON_LOGIN_BY_TELEGRAM_SUCCESS = 'ON_LOGIN_BY_TELEGRAM_SUCCESS';
export const ON_LOGIN_BY_TELEGRAM_FAILED = 'ON_LOGIN_BY_TELEGRAM_FAILED';

export const ON_LOGIN_BY_TWITTER = 'ON_LOGIN_BY_TWITTER';
export const ON_LOGIN_BY_TWITTER_SUCCESS = 'ON_LOGIN_BY_TWITTER_SUCCESS';
export const ON_LOGIN_BY_TWITTER_FAILED = 'ON_LOGIN_BY_TWITTER_FAILED';

export const GET_ME = 'GET_ME';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_FAILED = 'GET_ME_FAILED';

export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';

export const UPDATE_ME = 'UPDATE_ME';
export const UPDATE_ME_SUCCESS = 'UPDATE_ME_SUCCESS';
export const UPDATE_ME_FAILED = 'UPDATE_ME_FAILED';
